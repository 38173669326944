.projects {
  background-image: url('../images/projects-bg.svg');
  background-repeat: no-repeat;
  background-position: left em(86);
  background-size: contain;

  .grid:first-of-type {
    grid-template-areas: "card-1 card-1 card-1 card-2 card-2 card-2";

    @include until(tablet-portrait) {
      grid-template-areas:
        "card-1 card-1 card-1 card-1 card-1 card-1"
        "card-2 card-2 card-2 card-2 card-2 card-2";
      justify-items: center;
    }
  }

  .grid:last-of-type {
    grid-template-areas:
      "content content content content content free";

      @include until(tablet-portrait) {
        grid-template-areas:
          "content content content content content content";
      }
  }

  .card {
    max-width: 520px;
    margin: em(70) 0;
    background-color: $white;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);

    @include until(tablet-portrait) {
      max-width: 450px;
    }

    &:hover {
      box-shadow: none;
    }

    &:first-of-type {
      grid-area: card-1;
    }

    &:last-of-type {
      grid-area: card-2;
      justify-self: end;

      @include until(tablet-portrait) {
        justify-self: auto;
        margin-top: 0;
      }
    }

    &__header {
      max-height: 259px;

      img {
        width: 100%;
      }
    }

    &__body {
      padding: em(45);
      text-align: center;

      @include until(tablet) {
        padding: em(20) em(30);
      }
    }

    &__title {
      margin-top: 0;

      a {
        color: $text__color;
        font-size: em(26);
        line-height: em(36, 26);

        @include until(tablet) {
          font-size: em(23);
        }

        &:hover,
        &:focus {
          color: $color-secondary;

          .title__text {
            border-bottom-color: currentColor;
          }
        }
      }
      .title__container {
        display: block;
        text-align: center;
      }

      .title__text {
        border-bottom: 1px solid transparent;
      }
    }
  }

  .small-projects {
    grid-area: content;
  }

  .project {
    margin-bottom: em(50);

    &__header {
      display: flex;
      justify-content: start;
      align-items: center;
      flex-wrap: wrap;

      @include until(tablet) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__title {
      margin: 0;
    }

    &__link {
      margin-right: em(34, 26);
      border-bottom: 1px solid $neutral--300;
      color: $text__color;
      font-size: em(26);
      line-height: em(36, 26);

      @include until(tablet) {
        font-size: em(23);
      }

      &:hover,
      &:focus {
        color: $color-secondary;
        border-bottom-color: currentColor;
      }
    }

    &__body {
      font-size: em(20);
      line-height: em(34, 20);
      font-weight: 300;
    }
  }

  .tags {
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
      display: inline-block;
      margin-right: em(15, 14);
      padding: em(1,14) em(10,14) em(2,14) em(10,14);
      border-radius: 2px;
      background-color: $blue--500;
      color: $white;
      font-weight: 600;
      font-size: em(14);
      line-height: 1.5;

      @include until(tablet) {
        font-size: em(16);
      }

      &.in-progress {
        background-color: $coral--300;
      }

      &.done {
        background-color: $green--500;
      }
    }
  }
}
.speaking {
  background-image: url('../images/speaking-bg.png');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;

  .grid:first-of-type {
    grid-template-areas: "sidebar highlight highlight highlight highlight highlight";

    @include until(laptop) {
      grid-template-areas:
        "sidebar sidebar sidebar sidebar sidebar sidebar"
        "highlight highlight highlight highlight highlight highlight";
    }
  }

  .grid:last-of-type {
    grid-template-areas: "video-1 video-1 video-2 video-2 video-3 video-3";

      @include until(mobile) {
        grid-template-areas:
          "video-1 video-1 video-1 video-1 video-1 video-1"
          "video-2 video-2 video-2 video-2 video-2 video-2"
          "video-3 video-3 video-3 video-3 video-3 video-3";
        justify-items: center;
      }
  }

  .border {
    border-top: 1px solid rgba($neutral--500, 0.35);
  }
  .highlight {
    grid-area: highlight;
    margin: em(70) 0 em(55);

    @include until(laptop) {
      margin-top: 0;
    }

    &__date {
      grid-area: sidebar;
      max-width: 210px;
      margin-top: em(70,18);
      font-family: $accent__font-family;
      font-size: em(18);
      font-weight: 300;
      font-style: italic;
      color: $neutral--500;

      @include until(laptop) {
        max-width: 100%;
      }
    }

    &__header {
      max-width: 856px;
      max-height: 482px;

      img,
      iframe {
        width: 100%;
      }
    }

    &__title {
      margin-top: em(34, 20);
      margin-bottom: em(8, 20);
      font-size: em(22);
      line-height: em(36, 20);
      font-weight: 600;
    }

    &__link {
        color: $neutral--500;

        &:hover,
        &:focus {
          color: $white;
        }
      }

    &__text {
      margin: 0;
      color: $neutral--500;
    }

    &__hashtags {
      color: $white;
    }
  }

  .video {
      margin: em(70) 0;

      @include until(mobile) {
        max-width: 350px;
        margin-bottom: 2.5em;
      }

      &:first-of-type {
        grid-area: video-1;
      }

      &:nth-of-type(2) {
        grid-area: video-2;

        @include until(mobile) {
          margin-top: 0;
        }
      }

      &:last-of-type {
        grid-area: video-3;

        @include until(mobile) {
          margin-top: 0;
        }
      }

      &__header {
        max-width: 350px;
        max-height: 230px;

        img {
          width: 100%;
        }
      }

      &__title {
        margin-top: em(18, 20);
        font-size: em(20);
        line-height: em(34, 20);
        font-weight: 600;
      }

      &__link {
          color: $neutral--500;

          &:hover,
          &:focus {
            color: $white;
          }
        }
    }
}
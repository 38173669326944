/* === COLORS === */

/* Neutral */
$white:       #FFFFFF;
$neutral--200:    #FAFAFA;
$neutral--300:    #D9D9D9;
$neutral--500:    #89929C;
$neutral--800:    #142045;
$black:       #000;

/* Primary */
$cyan--300:    #2BC1E2;
$cyan--500:    #16B5D8;
$cyan--700:    #0791B0;

/* Secondary */
$coral--300:   #FF8282;
$coral--500:   #F55F5F;

/* Accents */
$green--500:   #72D67F;
$blue--500:    #78CEEF;


/* === THEME === */
$color-primary: $cyan--300;
$color-primary--middark: $cyan--500;
$color-primary--dark: $cyan--700;

$color-secondary--soft: $coral--300;
$color-secondary: $coral--500;


/* === TYPE === */
$base__font-family: 'Montserrat', 'Helvetica Neue', Helvetica, sans-serif;
$accent__font-family: 'Merriweather', serif;

$text__color: $neutral--800;

.blog {
  background-image: url('../images/blog-bg.svg');
  background-repeat: no-repeat;
  background-position: right em(105);
  background-size: contain;

  .grid {
    grid-template-areas:
      "content content content content content free";

      @include until(tablet-portrait) {
        grid-template-areas:
          "content content content content content content";
      }
  }

  &__content {
    grid-area: content;
  }

  .post {
    margin-top: em(40);
    margin-bottom: em(50);

    &__header {
      display: flex;
      justify-content: start;
      align-items: center;
    }

    &__date {
      font-size: em(14);
    }

    &__title {
      margin: 0;
    }

    &__link {
      color: $white;
      font-size: em(22);
      line-height: em(34, 22);

      @include until(tablet) {
        font-size: em(20);
      }

      &:hover,
      &:focus {
        color: $white;
        border-bottom-color: currentColor;
      }
    }

    &__body {
      font-size: em(20);
      line-height: em(34, 20);
      font-weight: 300;
      color: $white;
    }

    &__text {
      margin: em(8,20) 0;
    }
  }
}
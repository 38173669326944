.button {
  &:focus,
  &:active {
    outline: 0;
  }

  &--primary {
    padding: em(22,14) em(84,14);
    background-color: $color-primary;
    border: 1px solid $color-primary;
    border-radius: 3px;
    color: $white;
    font-size: em(14);
    font-weight: 600;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background-color: $color-primary--middark;
      border-color: $color-primary--middark;
    }

    &:active {
      background-color: $color-primary--dark;
      border-color: $color-primary--dark;
    }
  }

  &--ghost {
    display: inline-block;
    margin-top: em(15, 20);
    margin-bottom: em(15, 20);
    padding: em(13, 20) em(56, 20);
    border: 1px solid currentColor;
    border-radius: 3px;
    color: $neutral--500;
    font-size: em(20);

    &:hover,
    &:focus {
      color: $color-primary;
    }

    &:active {
      background-color: $color-primary;
      border-color: $color-primary;
      color: $white;
    }
  }
}

.viewall {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__link {
    position: relative;
    margin-right: em(44);
    color: $white;
    line-height: 1.5;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: em(10, 20);
      right: em(-44);
      width: em(28);
      height: em(11);
      background-image: url('../images/arrow.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }

    &--dark {
      color: $neutral--500;

      &::after {
        background-image: url('../images/arrow-dark.svg');
      }
    }
  }
}
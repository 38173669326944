.hero {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100vh;
  padding: em(36);
  background-image: url('../images/hero.png'), url('../images/circle.svg');
  background-repeat: no-repeat,  no-repeat;
  background-position: right bottom, 380px -55px;
  background-size: contain, 111px;
  box-sizing: border-box;

  @include until(laptop) {
    flex-direction: column;
    height: auto;
  }

  @include until(tablet) {
    height: auto;
    background-image: none;
    padding-left: 8vw;
    padding-right: 8vw;
  }

  .brand {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;

    @include until(laptop) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: auto;
      line-height: 0;
    }

    &__logo {

      &:hover,
      &:focus {
        border: 0;
      }

      @include until(laptop) {
        display: flex;
        align-items: center;
      }

      @include until(tablet-portrait) {
        img {
          height: 40px;
        }
      }
    }

    &__name {
      position: absolute;
      top: em(110, 22);
      left: em(-23, 22);
      color: $white;
      font-weight: 600;
      font-size: em(22);
      text-transform: uppercase;
      transform: rotate(-90deg);

      @include until(laptop) {
        position: initial;
        margin-left: 1em;
        transform: none;
      }
    }
  }

  .social {
    position: absolute;
    bottom: 0;
    padding: 0;
    margin: 0;
    text-align: center;
    list-style-type: none;

    @include until(laptop) {
      display: flex;
      align-items: center;
      position: initial;
      line-height: 0;
    }

    &__item {
      display: inline-block;
      position: relative;

      &:not(:first-of-type) {
        padding-top: 2em;

        @include until(laptop) {
          padding-top: 0;
          padding-left: 2em;
        }

        &::before {
          content: '\\';
          position: absolute;
          top: 0;
          left: 12px;
          font-size: 26px;
          color: $white;
          transform: rotate(-45deg);

          @include until(laptop) {
            top: 12px;
            left: 12px;
            transform: none;
          }
        }
      }
    }

    &__link {
      &:hover,
      &:focus {
        border: 0;
      }

      @include until(tablet-portrait) {
        img {
          height: 24px;
        }
      }
    }
  }

  &__content {
    flex-grow: 1;

    @include until(tablet) {
      width: 100%;
    }
  }

  .nav {
    float: right;
    margin: 0;
    margin-right: 14vw;

    @include until(laptop) {
      display: none;
    }

    &__item {
      display: inline-block;
      position: relative;
      padding: 0 em(20) 0 calc(1em + 6px);

      &:not(:first-of-type)::before {
        content: '';
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        width: 6px;
        height: 6px;
        background-color: $color-secondary;
        border-radius: 50%;
      }
    }

    &__link {
      color: $white;
      font-size: em(20);
    }
  }

  .intro {
    max-width: em(635);
    margin-left: 14vw;
    margin-top: 14vw;

    @include until(desktop-xl) {
      margin-left: 7vw;
    }

    @include until(desktop) {
      margin-left: 4.5vw;
      font-size: 16px;
    }

    @include until(laptop) {
      margin-top: 4.5vw;
      margin-left: 6.5vw;
      max-width: em(535);
      font-size: 15px;
    }

    @include until(tablet-portrait) {
      font-size: 14px;

    }

    @include until(tablet) {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 0;

      br {
        display: none;
      }
    }

    &__title {
      font-size: em(50);
      font-weight: 300;
      line-height: em(73, 50);

      @include until(tablet) {
        width: 84%;
        font-size: em(40);
      }

      @include until(mobile) {
        width: 100%;
      }

      .bold {
        font-weight: 600;
      }

      .tag {
        padding: 0 em(14, 50);
        background-color: $color-secondary;
        border-radius: 3px;
        color: $white;
      }
    }

    &__text {
      width: 84%;
      margin-top: 1em;
      font-size: em(26);
      line-height: em(42, 26);
    }
  }


}
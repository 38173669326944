.about {
  .grid {
    grid-template-areas: "content content content content sidebar sidebar";

    @include until(laptop) {
      grid-template-areas:
          "content content content content content content"
          "sidebar sidebar sidebar sidebar sidebar sidebar";
    }
  }

  &__content {
    grid-area: content;
  }

  &__sidebar {
    grid-area: sidebar;

    @include until(laptop) {
      display: flex;
      justify-content: space-between;
    }

    @include until(mobile) {
      flex-direction: column;
    }
  }

  h3 {
    margin-top: 0.7em;
    font-size: em(22);
    font-weight: 600;
    text-transform: uppercase;
  }

  p:first-of-type {
    margin-top: em(70);
  }

  .ul {
    margin-bottom: em(42);
  }

  a {
    color: $text__color;
    font-weight: 400;

    &:hover,
    &:focus {
      color: $color-secondary;
    }
  }

  &__text {
    max-width: em(635);

    @include until(desktop) {
      max-width: em(490, 18);
    }

    @include until(laptop) {
      max-width: 90%;
    }

    @include until(mobile) {
      max-width: 100%;
    }
  }

  .quote {
    position: relative;
    box-sizing: border-box;

    @include until(laptop) {
      margin: 4.2em 0;
    }

    @include until(mobile) {
      margin-top: 0;
    }


    &__bg {
      position: absolute;
      top: 62px;
      left: 62px;
      width: 100%;
      height: 280px;
      background-image: url('../images/quote-bg.svg');
      background-repeat: no-repeat;
      background-position: right bottom;
      z-index: -1;

      @include until(tablet) {
        top: 35px;
        left: 35px;
        height: 240px;
        background-repeat: repeat;
      }

      @include until(mobile) {
        left: 15px;
        height: 195px;
      }
    }

    &__text {
      box-sizing: border-box;
      height: 280px;
      padding: 1em em(40, 24);
      background-color: $color-secondary;
      border-radius: 3px;
      color: $white;
      font-family: $accent__font-family;
      font-style: italic;
      font-weight: 300;
      font-size: em(24);
      line-height: em(36, 24);

      @include until(desktop) {
        padding: .8em 1.3em;
      }

      @include until(laptop) {
        max-width: 300px;
      }

      @include until(tablet) {
        max-width: 250px;
        height: 240px;
      }

      @include until(mobile) {
        max-width: 100%;
        height: 195px;
      }


      &::before {
        content: '';
        display: block;
        width: 50px;
        height: 40px;
        margin-bottom: .5em;
        background-image: url('../images/quote.svg');
        background-repeat: no-repeat;
      }
    }
  }
}
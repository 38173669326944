@mixin until($point) {
  @if $point == desktop-xl {
    @media screen and (max-width: 1600px) { @content; }
  }
  @if $point == desktop {
    @media screen and (max-width: 1400px) { @content; }
  }
  @if $point == laptop {
    @media screen and (max-width: 1110px) { @content; }
  }
  @else if $point == tablet-portrait {
    @media (max-width: 950px) { @content; }
  }
  @else if $point == tablet {
    @media (max-width: 800px)  { @content; }
  }
  @else if $point == mobile {
    @media (max-width: 600px)  { @content; }
  }
}

body {
  font-family: $base__font-family;
  color: $text__color;
  font-size: 20px;
  line-height: 34px;
  font-weight: 300;

  @include until(desktop) {
    font-size: 18px;
  }

  @include until(tablet-portrait) {
    font-size: 17px;
  }

  @include until(tablet) {
    font-size: 16px;
  }
}

.h2 {
  display: inline-block;
  padding-right: em(14, 42);
  margin: 0;
  background-image: url('../images/h2.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 1px) em(22, 42);
  background-size: em(6, 42) em(18, 42);
  font-size: em(42);
  font-weight: 600;
  line-height: 1.25;

  @include until(tablet) {
    font-size: em(35);
  }

  &--light {
    color: $white;
  }
}

a {
  text-decoration: none;
  border-bottom: 1px solid transparent;

  &:hover,
  &:focus {
    border-bottom: 1px solid currentColor;
  }
}

.a {
  color: $neutral--800;
  border-bottom-color: $neutral--300;

  &:hover,
  &:focus {
    color: $color-secondary;
    border-bottom-color: $color-secondary;
    outline: 0;
  }
}

h3, .h3 {
  font-size: 20px;
  margin-top: em(40, 20);
  margin-bottom: em(14, 20);
  line-height: 1.5;
  font-weight: 600;

  @include until(tablet) {
    font-size: 18px;
  }
}

.h3 {
  text-transform: uppercase;
}

.ul {
  padding-left: 12px;
  list-style: none;
}

.li::before {
  content:'';
  display: inline-block;
  position: relative;
  left: -12px;
  width: 0;
  height: 0;
  margin-right: 6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid $color-secondary;
}

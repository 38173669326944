.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 em(54);
  background-color: $neutral--800;

  @include until(mobile) {
    flex-direction: column;
    justify-content: center;
  }

  &__credits {
    margin: 1em 0;
    color: $neutral--300;

    @include until(mobile) {
      margin-top: 0;
    }
  }

  &__muted {
    color: $neutral--500;
    font-size: 0.8em;

    a {
      color: $neutral--500;
    }
  }

  .brand {
    &__logo {
      display: flex;
      align-items: center;
      margin: em(24) 0;
      border: 0;
      color: $neutral--500;

      &:hover,
      &:focus {
        border: 0;
      }

      @include until(tablet-portrait) {
        img {
          height: 40px;
        }
      }
    }

    &__name {
      margin-left: em(28, 22);
      color: $white;
      font-weight: 600;
      font-size: em(22);
      text-transform: uppercase;
    }
  }
}
section {
  padding: 8vw 15vw;

  @include until(tablet) {
    padding: 14vw 8vw;
  }
}
#hero, #blog {
  background-color: #2BC1E2;
}
#projects {
  background-color: #FAFAFA;
}
#speaking {
  background-color: #0D1C2F;
}
.contact {
  .grid {
    grid-template-areas:
      "sidebar free form form form form";

      @include until(tablet-portrait) {
        grid-template-areas:
          "sidebar sidebar sidebar sidebar sidebar sidebar"
          "form form form form form form";
      }
  }

  &__sidebar {
    grid-area: sidebar;
    position: relative;
  }

  &__form {
    grid-area: form;
  }

  label {
    display: none;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    margin-bottom: em(30);
    padding: em(11) em(15);
    background-color: $neutral--200;
    border: 1px solid $neutral--500;
    border-radius: 3px;
    font-size: em(20);
    line-height: em(34);
    box-sizing: border-box;

    &:focus {
      outline: 0;
      border-color: $color-primary;
    }
  }

  &__text {
    max-width: em(190, 18);
    margin-top: em(70, 18);
    font-family: $accent__font-family;
    font-weight: 300;
    font-style: italic;
    font-size: em(18);
    line-height: em(30, 18);

    @include until(tablet-portrait) {
      max-width: 100%;
      margin-top: em(35, 18);
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__input {
    width: 48%;

    @include until(mobile) {
      width: 100%;
    }
  }

  &__gdpr {
    display: block;
    line-height: 1.5;
    margin-bottom: em(30, 20);
  }

  .social {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    padding: 0;
    margin: 0;
    margin-bottom: em(15);
    text-align: center;
    list-style-type: none;
    line-height: 0;

    @include until(tablet-portrait) {
      display: none;
    }

    &__item {
      position: relative;

      &:not(:first-of-type) {
        padding-left: 2em;

        &::before {
          content: '/';
          position: absolute;
          top: 14px;
          left: 16px;
          font-size: 26px;
          color: $neutral--500;
        }
      }
    }

    &__link {
      &:hover,
      &:focus {
        border: 0;
      }
    }
  }
}
.container {
  max-width: 1110px;
  margin: 0 auto;
}

.grid {
  display: grid;
  grid-template-columns: 1.5fr .5fr repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
  margin-bottom: 15px;
}
